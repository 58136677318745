<template>
  <page pageTitle="" isFluid customClass="is-projects mt-6" :hasRightBar="false">

    <div v-if="path">

      <div class="mt-6 block">
        <project-info :fullPath="path" />
      </div>

      <div class="tabs is-medium">
        <ul>
          <li :class="{'is-active': currentView === 'ProjectIssues'}"><a class="has-text-weight-semibold" @click.prevent="switchView('ProjectIssues')">Issues</a></li>
          <li :class="{'is-active': currentView === 'ProjectStats'}"><a class="has-text-weight-semibold" @click.prevent="switchView('ProjectStats')">Stats</a></li>

          <li :class="{'is-active': currentView === 'ProjectActivity'}"><a class="has-text-weight-semibold" @click.prevent="switchView('ProjectActivity')">Activities</a></li>
        </ul>
      </div>



      <div class="is-relative">
          <component :is="currentView" :fullPath="path"></component>
      </div>

    </div>

  </page>
</template>

<script>

import {reactive, toRefs, defineAsyncComponent} from 'vue'
import { useRouter } from 'vue-router'
import {useUser} from "@/use/useUser";


export default {
  components: {
    Page: defineAsyncComponent(() => import('@/components/layout/Page')),
    ProjectInfo: defineAsyncComponent(() => import('@/components/projects/ProjectInfo')),
    ProjectStats: defineAsyncComponent(() => import('@/components/projects/ProjectStats')),
    ProjectIssues: defineAsyncComponent(() => import('@/components/projects/ProjectIssuesList')),
    ProjectActivity: defineAsyncComponent(() => import('@/components/projects/ProjectActivity')),
  },
  setup(props, context) {
    const { can } = useUser()
    const router = useRouter()
    const state = reactive({
      path: '',
      currentView: 'ProjectIssues'
    })

    if (context.attrs.path) {
      localStorage.path = context.attrs.path
      state.path = context.attrs.path
    } else if (localStorage.path) {
      state.path = localStorage.path
    } else {
      router.push({
        path: '/projects'
      })
    }


    const switchView = (val) => {
      state.currentView = val
    }



    return {
      ...toRefs(state),
      can,
      switchView
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
